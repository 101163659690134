import type * as React from 'react';

import type { Xinglet, RuntimeHost } from '@xing-com/crate-xinglet';

export default class RootXinglet implements Xinglet {
  public getComponent(
    host: RuntimeHost
  ): React.ComponentType<{ basePath: string }> {
    return ({ basePath }) => {
      return (
        <host.XingletLoader
          name="@xing-com/crate-communication-root"
          baseSite="lebenslauf"
          basePath={basePath}
        />
      );
    };
  }
}
